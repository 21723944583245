import React, { createContext } from "react";
import { Admin } from "../helpers/api";
import { isNull, isEmpty } from "lodash";

import { sendNotification } from "../helpers/notification";

export const AdminContext = createContext();

export class AdminProvider extends React.Component {
  state = {
    getCorrections: async () => {
      await Admin.getCorrections()
        .then((res) => {
          this.setState({ corrections: res.body.records });
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
          this.setState({
            redirectPath: "/",
            redirectMessage: error.response.body.message
              ? error.response.body.message
              : "Что то пошло не так. ⚠️",
            redirect: true,
          });
        });
    },

    getSettings: async () => {
      await Admin.getSettings()
        .then((res) => {
          this.setState({ settings: res.body });
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
          this.setState({
            redirectPath: "/",
            redirectMessage: "Что то пошло не так. ⚠️",
            redirect: true,
          });
        });
    },

    // getUsersShortList: async () => {
    //   await Admin.getUsersShortList().then((res) => {
    //     this.setState({ users_short_list: res.body });
    //   })
    //   .catch((error) => {
    //     sendNotification("warning", "Не удалось загрузить пользователей для фильтра ⚠️", `${error.response?.body?.message}`);
    //   });
    // },

    getPendingWithdrawsCount: async () => {
      await Admin.getPendingWithdrawsCount().then((res) => {
        this.setState({ pending_withdraws_count: res.body.count });
      })
      .catch((error) => {
        sendNotification("warning", "Не удалось количество заявок на вывод для меню ⚠️", `${error.response?.body?.message}`);
      });
    },

    getTraderSuccesCount: async () => {
      await Admin.getTraderSuccesCount().then((res) => {
        this.setState({ trader_success_count: res.body.count });
      })
      .catch((error) => {
        sendNotification("warning", "Не удалось получить количество заявок трейдеров ⚠️", `${error.response?.body?.message}`);
      });
    },

    updateSettings: async () => {
      let params = {};
      if (!isEmpty(this.state.settings.goldexProfit)) params.goldex_profit = Number(this.state.settings.goldexProfit);
      if (!isEmpty(this.state.settings.baybitStep)) params.baybit_step = Number(this.state.settings.baybitStep);
      if (!isEmpty(this.state.settings.autoPayment)) params.autoPayment = [true, 'true'].includes(this.state.settings.autoPayment);
      
      await Admin.updateSettings(params).catch((error) => {
        console.log("error", JSON.stringify(error, null, 4));
        this.setState({
          redirectPath: "/",
          redirectMessage: "Что то пошло не так. ⚠️",
          redirect: true,
        });
      });
    },

    updateState: async (key, val) =>
      await this.setState((state) => {
        state[key] = val;
        return state;
    }),

    updateSettingsValue: async (key, value) => {
      const updatedSettings = { ...this.state.settings };
      updatedSettings[key] = value;
      this.setState({ settings: updatedSettings });
    },

    confirmTraderOrder: async (id, manager_id, status) => {
      await Admin.confirmTraderOrder({ id, manager_id, status}).then((res) => {
        if (status === 'ACCEPTED') {
          sendNotification("success", "Успешно", "Заявка подтверждена, и переведена в успех.");
        }
        if (status === 'DECLINED') {
          sendNotification("success", "Успешно", "Заявка отменена, и переведена в ошибку.");
        }
      })
      .catch((error) => {
        sendNotification("warning", "Не удалось количество заявок трейдеров ⚠️", `${error.response?.body?.message}`);
      });
    },

    updateOrder: async () => {
      let params = {};
      params.id = this.state.activeOrder;
      params.status = this.state.status;
      if (!isEmpty(this.state.course)) params.course = this.state.course.replace(',', '.');

      await Admin.updateOrder(params).then((res) => {
        sendNotification("success", "Успешно", "Заявка успешно получила новый статус.");
      }).catch((error) => {
        sendNotification("danger", "Ошибка ⚠️", error.response?.body?.message);
      });

      await this.state.clear();
    },

    getTotals: async () => {
      await Admin.getTotals()
        .then((res) => {
          this.setState({ totals: res.body });
        })
        .catch((error) => {
          this.setState({
            redirectMessage: "Что то пошло не так. ⚠️",
            redirect: true,
          });
        });
    },

    getSummaries: async () => {
      await Admin.getSummaries()
        .then((res) => {
          this.setState({ SummariesData: res.body });
        })
        .catch((error) => {
          this.setState({
            redirectMessage: "Что то пошло не так. ⚠️",
            redirect: true,
          });
        });
    },

    addSummary: async () => {
      if (isEmpty(this.state.summary.rub) || isEmpty(this.state.summary.usdt)) {
        sendNotification("warning", "Ошибка ⚠️", "Поля сумм не могут быть пустыми.");
        return;
      }

      await Admin.addSummary({ rub: Number(this.state.summary.rub), usdt: Number(this.state.summary.usdt) }).then(async (res) => {
        if (res.body.result === "success") {
          await this.setState({ 
            summary: {}, 
            totals: {},
            SummariesData: [],
          });
          await sendNotification("success", "Успешно", "Корректировка добавлена.");
          await this.state.getTotals();
          await this.state.getSummaries();
        }
      }).catch((error) => {
        sendNotification("danger", "Ошибка ⚠️", error.response?.body?.message);
      });
    },

    addProviderSummary: async (provider) => {
      if (isEmpty(this.state.provider_summary.rub) || isEmpty(this.state.provider_summary.usdt)) {
        sendNotification("warning", "Ошибка ⚠️", "Поля сумм не могут быть пустыми.");
        return;
      }

      await Admin.addProviderSummary(provider, { rub: Number(this.state.provider_summary.rub), usdt: Number(this.state.provider_summary.usdt) }).then(async (res) => {
        if (res.body.result === "success") {
          await this.setState({ 
            provider_summary: {}, 
            provider_stats: {},
          });
          await sendNotification("success", "Успешно", `Корректировка ${provider} добавлена.`);
          await this.state.getProviderStats(provider);
        }
      }).catch((error) => {
        sendNotification("danger", "Ошибка ⚠️", error.response?.body?.message);
      });
    },

    processingOrder: async (id) => {
      await Admin.processingOrder({ id }).then((res) => {
        if (res.body.status === "success") {
          sendNotification("success", "Успешно", "Заявка отправлена статус.");
        }
      }).catch((error) => {
        sendNotification("danger", "Ошибка ⚠️", error.response?.body?.message);
      });

      await this.state.clear();
    },

    // sendTo7Sber: async (id) => {
    //   await Admin.sendTo7Sber({ id }).then((res) => {
    //     if (res.body.status === "success") {
    //       sendNotification("success", "Успешно", "Заявка отправлена в телеграм.");
    //     }
    //   }).catch((error) => {
    //     sendNotification("danger", "Ошибка ⚠️", error.response?.body?.message);
    //   });

    //   await this.state.clear();
    // },

    sendToVita: async (id) => {
      await Admin.sendToVita({ id }).then((res) => {
        if (res.body.status === "success") {
          sendNotification("success", "Успешно", "Заявка отправлена в Vita.");
        }
      }).catch((error) => {
        sendNotification("danger", "Ошибка ⚠️", error.response?.body?.message);
      });

      await this.state.clear();
    },

    getProviderStats: async (provider) => {
      await Admin.getProviderStats(provider).then((res) => {
        this.setState({ provider_stats: res.body });
      }).catch((error) => {
        sendNotification("danger", "Ошибка ⚠️", error.response?.body?.message);
      });
    },

    addProviderBallance: async (provider) => {
      if (isEmpty(this.state.provider_ballance)) {
        sendNotification("warning", "Ошибка ⚠️", "Сумма баланса не может быть пустой.");
        return;
      }

      await Admin.addProviderBallance(provider, { usdt: Number(this.state.provider_ballance) }).then(async (res) => {
        if (res.body.result === "success") {
          await this.setState({ 
            provider_ballance: null, 
            provider_stats: {},
          });
          await sendNotification("success", "Успешно", `Корректировка ${provider} добавлена.`);
          await this.state.getProviderStats(provider);
        }
      }).catch((error) => {
        sendNotification("danger", "Ошибка ⚠️", error.response?.body?.message);
      });
    },

    takeProfit: async (provider) => {
      if (isEmpty(this.state.take_amount)) {
        sendNotification("warning", "Ошибка ⚠️", "Сумма баланса не может быть пустой.");
        return;
      }

      await Admin.takeProfit(provider, { usdt: Number(this.state.take_amount) }).then(async (res) => {
        if (res.body.result === "success") {
          await this.setState({ 
            take_amount: null, 
            provider_stats: {},
          });
          await sendNotification("success", "Успешно", `Доход отминусован.`);
          await this.state.getProviderStats(provider);
        }
      }).catch((error) => {
        sendNotification("danger", "Ошибка ⚠️", error.response?.body?.message);
      });
    },

    addCardToDebt: async () => {
      let params = {};
      if (!isEmpty(this.state.card_to_debt)) params.card = this.state.card_to_debt;
      await Admin.addCardToDebt(params).then(async (res) => {
        if (res.body.result === "success") {
          await this.setState({ card_to_debt: null });
          this.state.getSettings();
          sendNotification("success", "Успешно", "Карта добавлена в черный список");
        }
      })
      .catch((error) => {
        sendNotification("warning", "Ошибка ⚠️", `${error.response?.body?.message}`);
      });
    },

    deleteCardFromDebt: async (card) => {
      await Admin.deleteCardFromDebt({ card }).then(async (res) => {
        if (res.body.result === "success") {
          this.state.getSettings();
          sendNotification("success", "Успешно", "Карта удалена из черного списка");
        }
      })
      .catch((error) => {
        sendNotification("warning", "Ошибка ⚠️", `${error.response?.body?.message}`);
      });
    },

    clear: () => {
      this.setState({
        corrections: [],
        settings: {},
        autoPayment: null,
        debtList: null,
        users_short_list: [],
        pending_withdraws_count: null,
        trader_success_count: null,
        activeOrder: null,
        status: null,
        summary: {},
        provider_summary: {},
        totals: {},
        SummariesData: [],
        goldexProfit: null,
        baybitStep: null,
        provider_stats: {},
        provider_ballance: null,
        card_to_debt: null,
        course: null,
      });
    },
    corrections: [],
    settings: {},
    rerenderSettings: false,
    debtList: null,
    summary: {},
    provider_summary: {},
    totals: {},
    SummariesData: [],
    provider_stats: {},
    provider_ballance: null,
    card_to_debt: null,
  };

  render() {
    return (
      <AdminContext.Provider value={this.state}>
        {this.props.children}
      </AdminContext.Provider>
    );
  }
}

export const AdminConsumer = AdminContext.Consumer;
